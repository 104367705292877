
/* :root {
    --vh: 1%;
} */

.in-homepage-container *:not(button) {
    color: #0C1222;
}

.in-homepage-container .in-text-color-1 {
    color: #0C1222 !important;
}

.in-homepage-container .in-text-color-2 {
    color: #181545 !important;
}

.in-homepage-container .h1 {
    font-size: 40px;
    line-height: 116%;
    letter-spacing: -1%;
}

.in-homepage-container .h2 {
    font-size: 32px;
    line-height: 116%;
    letter-spacing: -1%;
}

.in-homepage-container .h2-2 {
    font-size: 24px;
    font-weight: bold;
    line-height: 120%;
    letter-spacing: -1%;
    margin-bottom: 16px;
}

/* Navbar */
.in-homepage-nav-wrapper-parent {
    width: 100%;
    position: relative;
}

.in-homepage-nav-wrapper {
    position: absolute;
    z-index: 100;
    width: 100%;
}

.in-homepage-nav-wrapper, .in-homepage-nav-wrapper * {
    pointer-events: auto;
}

.in-homepage-nav {
    position: relative;
    padding: 14px 30px;
}

.in-homepage-nav-logo {
    margin-right: 64px;
}

.in-homepage-nav-logo > img {
    height: 35px;
    margin: auto 0;
}

.in-homepage-nav-links {
    position: relative;
    flex-grow: 1;
    align-items: center;
}

.in-nav-link {
    margin-right: 24px;
    padding: 5px 0;
    z-index: 20;
}

.in-nav-link > .dd-items-2 {
    margin-top: 30px;
}

.in-nav-link.show-on-hover > .dd-items-2 {
    z-index: 99;
}

.in-nav-link.show-on-hover > .dd-items-2:hover {
    display: block !important;
}

.in-nav-link.show-on-hover:hover > .dd-items-2 {
    display: block !important;
}

.in-homepage-nav-link {
    white-space: nowrap;
    height: fit-content;
    cursor: default;
    user-select: none;
}

.in-nav-link > img {
    margin-left: 6px;
    height: 13px;
    width: 16px;
    margin-top: 6px;
    filter: invert(53%) sepia(26%) saturate(178%) hue-rotate(185deg) brightness(94%) contrast(88%);
}

.in-hopepage-nav-cta > :first-child {
    margin-right: 16px;
}

.in-homepage-nav-cta button {
    white-space: nowrap;
    cursor: pointer;
}

/* General Sections */
.in-homepage-container {
    width: 100%;
    max-width: 1920px !important;
    margin: 0 auto;
    scroll-snap-type: y mandatory;
}

.in-homepage-section {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    position: relative;
}

.in-homepage-section-simple {
    overflow: hidden;
    position: relative;
}

.in-homepage-container .simple-input-wrapper > input {
    border-radius: 8px;
}

.in-input-err {
    color: #FF0000 !important;
    margin: 4px 0;
}

/* Viewport */
.in-homepage-viewport-bg {
    width: 88vw;
    height: 126vh;
    height: calc(var(--vh, 1vh) * 126);
    position: absolute;
    top: -24.55vh;
    top: calc(var(--vh, 1vh) * -24.55);
    left: 6vw;
    z-index: 0;
}

.in-homepage-viewport-bg-radial {
    height: 183vh;
    height: calc(var(--vh, 1vh) * 183);
    width: 112vw;
    position: absolute;
    z-index: -2;
    left: calc(50% - 112vw/2 - 4px);
    top: -92.9vh;
    top: calc(var(--vh, 1vh) * -92.9);
    background: linear-gradient(268.33deg, #F1F1FD 17.03%, #FFEBF5 42.16%, #FEF7DF 71.41%, #D1E4FF 85.53%);
    filter: blur(125px);
    border-radius: 50%;
}

.in-homepage-viewport-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 75px;
}

.in-homepage-viewport-content > .h1 {
    margin-top: 50px;
    margin-bottom: 16px;
    text-align: center;
}

.in-homepage-viewport-content > .text-0 {
    text-align: center;
    margin: 0;
}

.in-homepage-viewport-content > button {
    margin: 36px 0;
    cursor: pointer;
}

button.cta-arrow-btn > img {
    height: 13px;
    width: 16px;
    filter: invert(100%) sepia(7%) saturate(7500%) hue-rotate(147deg) brightness(127%) contrast(97%);
    transform: rotate(-90deg);
    opacity: 0.56;
    margin-left: 15px;
    margin-right: -5px;
    margin-bottom: 0;
    display: inline-block;
}

.in-homepage-viewport-content-video {
    height: 456px;
    width: 800px;
    max-width: 95vw;
    border: #fff solid 4px;
    border-radius: 32px;
    overflow: hidden;
    margin-bottom: 57px;
}

.in-homepage-viewport-content-video > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 32px;
}

/* Solution */
.in-homepage-solution {
    margin-bottom: 160px;
    position: relative;
}

.in-homepage-solution .h2 {
    margin-bottom: 35px;
}

.in-homepage-solution-text-abs-wrapper {
    width: 20vw;
    margin-left: 10vw;
    margin-right: 4.5vw;
}

.in-homepage-solution-text {
    position: sticky;
    left: 10vw;
    top: 0;
    width: 20vw;
    padding-top: 167px;
}

.in-homepage-solution-about .in-homepage-solution-about-content {
    padding: 4px 0;
}

.in-homepage-solution-about .vertical-line {
    height: inherit;
    width: 2px;
    margin-right: 10px;
}

.in-homepage-solution-about.active-solution-item .vertical-line {
    background-color: #006AFF;
}

.in-homepage-solution-about:not(.active-solution-item) .vertical-line {
    background-color: #DCDAFA;
}

.para-label-v2-gradient1 {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 9px;
    background: linear-gradient(90deg, rgba(52, 132, 245, 0.2) 0%, rgba(197, 111, 219, 0.2) 100%);
    border-radius: 300px;
}

.para-label-v2-gradient1 > .text-3-2 {
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.01em;
    background: linear-gradient(90deg, #006AFF 0%, #C56FDB 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0;
}

.para-label-v2-gradient2 {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 9px;
    background: linear-gradient(90deg, rgba(255, 235, 245, 0.6) 0%, rgba(255, 173, 173, 0.6) 100%);
    border-radius: 300px;
}

.para-label-v2-gradient2 > .text-3-2 {
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.01em;
    background: linear-gradient(90deg, #FF0080 0%, #F5222D 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0;
}

.para-label-v2-gradient3 {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px 9px;
    background: linear-gradient(90deg, rgba(249, 203, 40, 0.16) 0%, rgba(255, 110, 65, 0.16) 100%);
    border-radius: 300px;
}

.para-label-v2-gradient3 > .text-3-2 {
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.01em;
    background: linear-gradient(90deg, #F9CB28 0%, #FF6E41 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0;
}

.in-homepage-solution-about-content {
    width: calc(100% - 12px);
}

.in-homepage-solution-about-content > .h3 {
    margin-top: 0;
    margin-bottom: 8px;
}

.in-homepage-solution-about-content > p {
    margin: 0;
}

.in-homepage-solution-about:not(.active-solution-item) .in-homepage-solution-about-content > .text-1 {
    display: none;
}

.in-homepage-solution-about {
    cursor: pointer;
}

.in-homepage-solution-about:not(:last-child) {
    margin-bottom: 20px;
}

.in-homepage-solution-about:not(.active-solution-item) * {
    opacity: 0.7;
}

.in-homepage-solution-media {
    flex-grow: 1;
}

.in-homepage-solution-media-item {
    position: relative;
    padding: 167px 0;
    height: calc(100vh - 334px);
    height: calc((var(--vh, 1vh) * 100) - 334px);
    box-sizing: content-box;
}

.solution-media-container {
    position: relative;
    height: 72vh;
    height: calc(var(--vh, 1vh) * 72);
    width: 60vw;
    max-width: 1000px;
    border: 1.15px solid #E9EAEB;
    overflow: hidden;
    border-radius: 9px;
    background-color: #fff;
    z-index: 0;
}

.solution-media-container-bg {
    position: absolute;
    width: 100.9vw;
    height: 145.7vh;
    height: calc(var(--vh, 1vh) * 145.7);
    left: calc(50% - 1453.09px/2 + 118.3px);
    top: calc(50% - 1292.67px/2 + 170.82px);
    z-index: 0;
}

.solution-media-container > img:not(.solution-media-container-bg), .solution-media-container > video {
    /* height: fill-available;
    height: -webkit-fill-available; */
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    z-index: 1;
}

.solution-media-container > .solution-media-container-bottom-blur {
    position: absolute;
    width: 100%;
    height: 13.5vh;
    height: calc(var(--vh, 1vh) * 13.5);
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    z-index: 2;
}

.in-homepage-solution-gradient1 {
    position: absolute;
    width: 22vw;
    height: 22vw;
    right: 4vw;
    top: 6.5vh;
    top: calc(var(--vh, 1vh) * 6.5);
    background: #FEF7DF;
    filter: blur(50px);
    z-index: -2;
}

.in-homepage-solution-gradient2 {
    position: absolute;
    width: 31vw;
    height: 31vw;
    background: #FFEBF5;
    filter: blur(50px);
    z-index: -2;
}

/* Who We Are */
.in-homepage-who-we-are {
    padding: 0 10vw 80px;
}

.in-homepage-who-we-are-content-text > div {
    width: 50%;
}

.in-homepage-who-we-are-content-text {
    margin-bottom: 32px;
}

.in-homepage-who-we-are-content-text > div:first-child, 
.in-homepage-who-we-are-content-media > div:not(:last-child) {
    margin-right: 20px;
}

.in-homepage-who-we-are-content-text > .text-1 {
    margin-top: 9px;
}

.in-homepage-who-we-are-content-media-item {
    position: relative;
    max-width: 330px;
    min-width: 150px;
    overflow: hidden;
    box-sizing: content-box;
}

.in-homepage-who-we-are-content-media > .in-homepage-who-we-are-content-media-item:nth-child(1) {
    background: rgba(79, 70, 229, 0.08);
    border-radius: 8px;
    padding: 26px 20px;
}

.in-homepage-who-we-are-content-media > .in-homepage-who-we-are-content-media-item:nth-child(2) {
    background: rgba(255, 0, 128, 0.08);
    border-radius: 8px;
    padding: 26px 20px;
}

.in-homepage-who-we-are-content-media > .in-homepage-who-we-are-content-media-item:nth-child(3) {
    background: #FEF7DF;
    border-radius: 8px;
    padding: 26px 20px;
    width: 290px;
}

.in-homepage-who-we-are-content-media > .in-homepage-who-we-are-content-media-item:nth-child(4) {
    background: rgba(1, 97, 232, 0.08);
    border-radius: 8px;
    padding: 119px 20px 26px 20px;
}

.in-homepage-who-we-are-content-media-item > .flexColumn {
    margin-top: auto;
}

.in-homepage-who-we-are-content-media-item .h00 {
    font-size: 60px;
    font-weight: bold;
    line-height: 120%;
    letter-spacing: -1%;
}

.in-homepage-who-we-are-content-media > .in-homepage-who-we-are-content-media-item:nth-child(4) {
    display: flex;
    flex-direction: column;
}

.in-homepage-who-we-are-content-media > .in-homepage-who-we-are-content-media-item:nth-child(4) > .h00 {
    margin-top: auto;
}

.in-completion-rate-value {
    margin-top: 42px;
}

.in-rating-icon {
    height: 32px;
    margin-top: 3px;
    margin-right: 4px;
}

.in-bar-graph-icon {
    position: absolute;
    height: 129px;
    width: 204px;
    right: 20px;
    bottom: 26px;
}

.in-homepage-who-we-are-content-media-item > .h3 + .h1 {
    margin-top: 12px;
}

.in-homepage-who-we-are-content-media-item > .h3 + .text-1 {
    margin-top: 8px;
}

.in-homepage-who-we-are-content-media-item > .h2 + .text-1 {
    margin-top: 4px;
}

.in-homepage-who-we-are-content-media-item > .h00 + .h3 {
    margin-top: 8px;
}

.in-homepage-who-we-are-content-media-item > .wwa-media-item-icon {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 20px;
    top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(12, 18, 34, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.02), 0px 0px 1px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4.61538px;
    justify-content: center;
}

.wwa-media-item-icon > img {
    height: 37px;
    width: 37px;
    margin: auto;
}

/* Who We Serve */
.in-homepage-who-we-serve {
    background: rgba(0, 106, 255, 0.08);
}

.in-homepage-wws-bg {
    position: absolute;
    width: 1440px;
    height: 1120px;
    left: calc(50% - 1440px/2);
    top: calc(50% - 1120px/2 + 289px);
    background: radial-gradient(45.98% 49.59% at 48.85% 44.55%, rgba(226, 57, 148, 0.2) 24.28%, rgba(255, 255, 255, 0.004) 100%);
    opacity: 0.7;
    z-index: -2;
}

.in-homepage-wws-radial {
    position: absolute;
    width: 1269px;
    height: 1269px;
    left: calc(50% - 1269px/2 + 0.5px);
    top: 193px;
    background: rgba(255, 255, 255, 0.4);
    filter: blur(150px);
    z-index: -1;
}

.in-homepage-who-we-serve-content {
    padding: 100px 140px;
}

.in-homepage-who-we-serve-content > .h2 {
    text-align: center;
}

.in-wws-description {
    margin: 16px 0 40px;
    text-align: center;
}

.in-homepage-who-we-serve-media-item {
    position: relative;
    flex: 1 1 0;
    max-width: calc(100% - 40px);
    height: 367px;
    background: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
}

.in-homepage-who-we-serve-media-item:not(:last-child) {
    margin-right: 16px;
}

.in-homepage-who-we-serve-media-item > img {
    z-index: 0;
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    border-radius: 8px;
}

.wws-media-item-icon {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 8px;
    top: 8px;
    background: #F2F2FD;
    border-radius: 4px;
}

.wws-media-item-icon > img {
    height: 18px;
    width: 18px;
    margin: auto;
}

.wws-media-item-text-box {
    margin: auto 8px 8px 8px;
    background-color: #fff;
    border-radius: 6px;
    padding: 16px;
    z-index: 1;
    max-width: calc(100% - 48px);
    box-sizing: content-box;
}

.wws-media-item-text-box > h1 {
    margin: 0;
}

.wws-media-item-text-box > .text-3 {
    line-height: 20px;
    margin-top: 8px;
    margin-bottom: 0;
}

.in-homepage-who-we-serve-content > button.cta-arrow-btn {
    margin: 32px auto 0;
}

/* Benefits */
.in-homepage-benefits > .in-homepage-benefits-content {
    padding: 100px 8vw;
}

.in-homepage-benefits-content > .h2 {
    text-align: center;
}

.in-homepage-benefits-content > .text-1 {
    margin-top: 16px;
    margin-bottom: 24px;
    text-align: center;
}

.in-homepage-benefits-content > button.cta-arrow-btn {
    margin: 0 auto 40px;
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item {
    position: relative;
    overflow: hidden;
    flex: 1 1 0;
    border-radius: 8px;
    padding: 24px;
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item:nth-child(odd) {
    height: 289px;
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item:nth-child(even) {
    height: 335px;
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item:not(:last-child) {
    margin-right: 16px;
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item:nth-child(1) {
    background: #FFEBF5;
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item:nth-child(1) > .in-homepage-benefits-bg {
    background: radial-gradient(59.68% 65.9% at 52.19% 25.89%, rgba(255, 0, 128, 0.4) 24.28%, rgba(255, 255, 255, 0.008) 100%);
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item:nth-child(2) {
    background: rgba(79, 70, 229, 0.08);
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item:nth-child(2) > .in-homepage-benefits-bg {
    background: radial-gradient(59.68% 65.9% at 52.19% 25.89%, rgba(79, 70, 229, 0.4) 24.28%, rgba(255, 255, 255, 0.008) 100%);
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item:nth-child(3) {
    background: #FEF7DF;
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item:nth-child(3) > .in-homepage-benefits-bg {
    background: radial-gradient(59.68% 65.9% at 52.19% 25.89%, rgba(249, 203, 40, 0.4) 24.28%, rgba(255, 255, 255, 0.008) 100%);
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item:nth-child(4) {
    background: #EBF3FE;
}

.in-homepage-benefits-media > .in-homepage-benefits-media-item:nth-child(4) > .in-homepage-benefits-bg {
    background: radial-gradient(59.68% 65.9% at 52.19% 25.89%, rgba(0, 106, 255, 0.4) 24.28%, rgba(255, 255, 255, 0.008) 100%);
}

.in-homepage-benefits-media-item > .in-homepage-benefits-bg {
    position: absolute;
    width: 694.64px;
    height: 612.11px;
    left: calc(50% - 694.64px/2 + 217.32px);
    top: calc(50% - 612.11px/2 + 41.56px);
    z-index: 0;
    opacity: 0.4;
}

.benefits-media-item-icon {
    width: 80px;
    height: 80px;
    margin: 0 0 24px;
    background: #FFFFFF;
    box-shadow: 0px 6.6665px 26.666px rgba(12, 18, 34, 0.1), 0px 3.33325px 16.6663px rgba(0, 0, 0, 0.02), 0px 0px 1.66663px 1.66663px rgba(0, 0, 0, 0.04);
    border-radius: 13.333px;
}

.benefits-media-item-icon > img {
    height: 53px;
    width: 53px;
    margin: auto;
}

.in-homepage-benefits-media-item > .h2-2 {
    margin-bottom: 16px;
    margin-top: 0;
}

.in-homepage-benefits-media-item > h2 {
    margin: 0;
}

.in-homepage-benefits-media-item > *:not(.in-homepage-benefits-bg) {
    z-index: 1;
}

/* ROI Calc */
.in-homepage-roi-calc {
    position: relative;
    padding: 92px 0;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
}

.in-homepage-roi-gradient1 {
    position: absolute;
    width: 205px;
    height: 205px;
    left: 70.3vw;
    top: 39px;
    background: #FEF7DF;
    filter: blur(50px);
    z-index: -1;
}

.in-homepage-roi-gradient2 {
    position: absolute;
    width: 296px;
    height: 296px;
    left: 67.9vw;
    top: 127px;
    background: #EBF3FE;
    filter: blur(50px);
    z-index: -2;
}

.in-homepage-roi-calc-content {
    z-index: 0;
}

.in-homepage-roi-calc-text {
    width: 258px;
    margin-right: 80px;
    justify-content: center;
    align-items: start;
}

.in-homepage-roi-calc-text > .text-1 {
    margin: 16px 0 20px;
}

.in-homepage-roi-calc-media {
    width: 466px;
    max-width: calc(100% - 68px);
    padding: 24px;
    border: 1px solid #DCDEE1;
    border-radius: 8px;
    background-color: #fff;
    box-sizing: content-box;
}

.in-homepage-roi-calc-media .text-1-3 {
    font-weight: 400 !important;
    margin-bottom: 8px;
}

.in-homepage-roi-calc-media > .simple-input-container {
    margin: 20px 0;
}

.in-homepage-roi-calc-media > .checkbox-group-container {
    margin-bottom: 20px;
}

.in-homepage-roi-calc-media > .checkbox-group-container > .text-1 {
    margin-bottom: 8px;
}

.in-homepage-roi-calc-media .checkbox-group-items > div:first-child > .checkbox-container-1 {
    margin-right: 30px;
}

/* FAQs */
.in-homepage-faqs {
    background: rgba(0, 106, 255, 0.08);
}

.in-homepage-faqs-content {
    padding: 100px 140px;
}

.in-homepage-faqs-text {
    width: 311px;
    margin-right: 10vw;
}

.in-homepage-faqs-text > .text-1 {
    margin: 10px 0 20px;
}

.in-homepage-faqs-text > .flexRow > button:first-child {
    margin-right: 12px;
}

.in-homepage-faqs-media-item {
    width: calc(100% - 32px);
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 14px 16px;
    box-sizing: content-box;
}

.in-homepage-faqs-media-item .in-faqs-number {
    height: fit-content;
    width: 40px;
    color: #6D6D6D;
    text-align: left;
}

.in-homepage-faqs-media-item .in-faqs-text {
    height: fit-content;
    flex-grow: 1;
    text-align: left;
    margin-right: 8px;
    margin-top: 0;
    margin-bottom: 0;
}

.in-homepage-faqs-media-item .in-homepage-faqs-media-item-main {
    cursor: pointer;
}

.in-homepage-faqs-media-item .in-homepage-faqs-media-item-main .h2-2 {
    text-align: center;
    margin: -3px 0 0;
}

.in-homepage-faqs-media-item .in-faqs-expanded {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
}

.in-homepage-faqs-media-item .in-faqs-expanded-show-a {
    height: fit-content;
    max-height: 1500px !important;
    overflow: hidden;
    transition: max-height 0.6s ease-in-out;
}

/* Contact Us */
.in-homepage-contact-us-content {
    padding: 100px 8vw;
}

.in-homepage-contact-us-media {
    margin-right: 5.3vw;
}

.in-homepage-contact-us-content > div {
    flex: 1 1 0;
}

.in-homepage-contact-us-text > .in-bordered-box-left-icon {
    margin: 20px 0 40px;
    border: 1px solid #D3D3D6;
    padding: 8px;
    width: fit-content;
    border-radius: 8px;
}

.in-homepage-contact-us-text > .in-bordered-box-left-icon > .in-bbox-icon {
    height: 24px;
    width: 24px;
    margin-right: 12px;
}

.in-homepage-contact-us-text > .in-bordered-box-left-icon > .in-bbox-icon > img {
    height: inherit;
    width: inherit;
}

.in-homepage-contact-us-text > .in-bordered-box-left-icon > .text-1-3 {
    margin-top: 1px;
    text-decoration: none;
}

.in-homepage-contact-us-inputs {
    margin-bottom: 8px;
}

.in-homepage-contact-us-inputs .text-1-3 {
    font-weight: 400 !important;
    margin-bottom: 8px;
}

.in-homepage-contact-us-inputs > *:not(.flexRow):not(:last-child), .in-homepage-contact-us-inputs > .flexRow > * {
    margin-bottom: 20px;
    margin-top: 0;
}

.in-homepage-contact-us-inputs > .flexRow > *:first-child {
    margin-right: 16px;
}

.in-homepage-contact-us-inputs > .flexRow > * {
    width: calc(50% - 8px);
}

.in-homepage-contact-us .btn-primary {
    padding: 15px 20px;
    height: fit-content;
    width: fit-content;
}

/* Pre-Footer */
.in-homepage-pre-footer-bg {
    position: absolute;
    z-index: -1;
}

.in-homepage-pre-footer-radial1 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(269.41deg, #FFF4CC 28.7%, #FFC6E2 54.93%, #D1E4FF 86.14%);
    z-index: -2;
}

.in-homepage-pre-footer-radial2 {
    position: absolute;
    width: 127.4vw;
    height: 175.5vh;
    height: calc(var(--vh, 1vh) * 175.5);
    top: -103vh;
    top: calc(var(--vh, 1vh) * -103);
    left: calc(50% - 1835px/2 - 13.5px);
    background: #FFFFFF;
    filter: blur(125px);
    z-index: -2;
}

.in-homepage-pre-footer-content {
    padding-top: 56px;
    padding-left: 36px;
}

.in-homepage-pre-footer-content > .in-homepage-pre-footer-text {
    margin: auto;
    justify-content: center;
    align-items: start;
    min-width: 400px;
}

.in-homepage-pre-footer-text > .h1 {
    margin: 0;
}

.in-homepage-pre-footer-text > .text-0 {
    margin: 16px 0 24px;
}

.in-homepage-pre-footer-content > .in-homepage-pre-footer-media {
    justify-content: end;
    align-items: end;
}

.in-homepage-pre-footer-media > img {
    width: 100%;
    border-radius: 20px 0 0 0;
    margin-bottom: -20px;
}

/* Footer */
.in-homepage-footer {
    padding: 75px 80px 40px;
    background-color: #0C1222;
}

.in-homepage-footer * {
    color: #fff !important;
}

.in-homepage-footer-content-top {
    padding-bottom: 98px;
    padding-right: 147px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.in-homepage-footer-content-top > div > .h2 {
    line-height: 130%;
    margin: 0;
}

.in-homepage-footer-content-top > div > .h2 + .flexRow {
    margin-top: 24px;
}

.in-homepage-footer-content-top .footer-social-icon:not(:last-child) {
    margin-right: 16px;
    cursor: pointer;
}

.in-homepage-footer .footer-sitemap > * {
    margin-right: 73px;
}

.in-homepage-footer .footer-sitemap .text-1-3 {
    margin-bottom: 27px;
}

.in-homepage-footer .footer-sitemap .text-1 {
    cursor: pointer;
}

.in-homepage-footer .footer-sitemap .text-1 {
    display: block;
}

.in-homepage-footer .footer-sitemap .text-1:not(:last-child) {
    margin-bottom: 20px;
}

.in-homepage-footer .in-homepage-footer-content-bottom {
    padding-top: 40px;
    justify-content: space-between;
}

.in-homepage-footer-content-bottom > .flexRow > :not(:last-child) {
    cursor: pointer;
    padding: 4.5px 16px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.in-homepage-footer-content-bottom > .flexRow > :last-child {
    cursor: pointer;
    padding-top: 4.5px;
    padding-left: 16px;
}


/* Responsiveness */
@media only screen and (max-width: 1100px) {
    .simple-input-container > .simple-input-wrapper > input {
        height: 25px;
    }

    .dd-selector-2 {
        padding: 6.5px 15px 6.5px 16px;
    }

    .in-homepage-section {
        height: unset !important;
    }

    .in-homepage-who-we-are-content-media, .in-homepage-who-we-serve-media, .in-homepage-benefits-media {
        flex-wrap: wrap;
    }

    .in-homepage-who-we-are-content-text {
        flex-direction: column;
    }

    .in-homepage-who-we-are-content-text > .h2 {
        margin-right: unset;
        margin-bottom: 16px;
    }

    .in-homepage-who-we-are-content-text > div {
        width: unset;
        margin-top: unset;
    }

    .in-homepage-who-we-are-content-media > div {
        margin-bottom: 16px;
    }

    .solution-media-container > img:not(.solution-media-container-bg), .solution-media-container > video {
        /* height: max-content;         */
    }

    .in-homepage-who-we-are-content-media {
        width: 100%;
        justify-content: center;
    }

    .in-homepage-who-we-are-content-media > div {
        width: unset !important;
    }

    .in-homepage-who-we-are-content-media > div > * {
        width: fit-content;
    }

    .in-homepage-who-we-are-content-media > div:nth-child(3) {
        min-height: 170px;
    }

    .in-rating-icon {
        margin-top: 0;
    }

    .in-homepage-benefits-media > .in-homepage-benefits-media-item {
        height: unset !important;
        width: unset !important;
        margin: 0 !important;
        flex-basis: 100%;
    }

    .in-homepage-roi-calc-content {
        flex-direction: column;
    }
    
    .in-homepage-roi-calc-content > .in-homepage-roi-calc-text {
        text-align: center;
        margin-bottom: 40px;
    }

    .in-homepage-roi-calc-content > .in-homepage-roi-calc-text, .in-homepage-roi-calc-content > .in-homepage-roi-calc-text > * {
        margin-right: auto;
        margin-left: auto;
    }
}

@media only screen and (min-width: 750px) and (max-width: 1100px) {
    .in-homepage-who-we-are-content-media-item .h00 {
        font-size: 40px;
    }

    .in-homepage-container .h1 {
        font-size: 36px;
    }
    
    .in-homepage-container .h2 {
        font-size: 28px;
    }
    
    .in-homepage-container .h2-2 {
        font-size: 21px;
    }

    .in-homepage-solution {
        margin-bottom: 0;
    }

    .in-homepage-who-we-are,
    .in-homepage-who-we-serve-content,
    .in-homepage-benefits > .in-homepage-benefits-content,
    .in-homepage-faqs-content,
    .in-homepage-contact-us-content {
        padding: 100px 5vw;
    }

    .in-homepage-solution-text-abs-wrapper {
        width: 25vw;
        margin-left: 5vw;
    }
    
    .in-homepage-solution-text {
        width: 25vw;
        left: 5vw;
    }

    .in-homepage-solution-about:not(.active-solution-item) .in-homepage-solution-about-content > div:not(.in-homepage-solution-about-item-title):not(.h3) {
        display: none;
    }

    .in-homepage-who-we-are-content-media {
        width: 100%;
        justify-content: center;
    }

    .in-homepage-who-we-are-content-media > div {
        flex-basis: calc(50% - 50px);
        width: unset !important;
    }

    .in-homepage-who-we-are-content-media > div > * {
        width: fit-content;
    }

    .in-homepage-who-we-are-content-media > div:nth-child(even) {
        margin-right: unset !important;
    }

    .in-homepage-who-we-serve-media-item {
        flex-basis: calc(50% - 32px);
        max-width: unset !important;
        margin-bottom: 16px;
    }

    .in-homepage-who-we-serve-media-item:nth-child(even) {
        margin-right: unset !important;
    }

    .in-homepage-benefits-media-item .text-3 {
        font-size: 16px;
    }

    .in-homepage-contact-us-media > img {
        width: 100%;
    }
}

@media only screen and (min-width: 250px) and (max-width: 750px) {
    .in-homepage-who-we-are-content-media-item .h00 {
        font-size: 36px;
    }

    .in-homepage-container .h1 {
        font-size: 32px;
    }

    .in-homepage-container .text-0 {
        font-size: 14px;
    }
    
    .in-homepage-container .h2 {
        font-size: 24px;
    }
    
    .in-homepage-container .h2-2 {
        font-size: 18px;
    }
    
    .in-homepage-nav {
        padding: 14px 16px;
    }

    .in-homepage-nav-logo {
        margin-right: 0;
    }

    .in-homepage-viewport-content > button {
        margin: 20px 0 36px;
    }

    .in-homepage-solution {
        max-height: unset;
        margin-bottom: 60px;
    }

    .in-homepage-solution-content {
        flex-direction: column !important;
    }

    .in-homepage-solution-text-abs-wrapper {
        margin-left: 0;
        width: unset;
        margin-right: 0;
    }

    .in-homepage-solution-text {
        position: relative;
        top: unset;
        left: unset;
        padding-top: unset;
        margin-top: 60px;
        margin-left: 4vw;
        margin-right: 4vw;
        width: 92vw;
    }

    .vertical-line {
        display: none;
    }

    .in-homepage-solution-media-item {
        padding: 20px 0;
        width: 100%;
        height: fit-content;
    }

    .solution-media-container {
        width: 100%;
        height: fit-content;
        padding: 40px 0;
    }

    .solution-media-container-bg {
        left: 0;
        top: -20vh;
        top: calc(var(--vh, 1vh) * -20);
    }

    .in-homepage-solution-about {
        position: relative;
    }

    .in-homepage-solution-about:not(.active-solution-item) * {
        opacity: 1;
    }

    .in-homepage-solution-about:not(.active-solution-item) .in-homepage-solution-about-content > .text-1 {
        display: unset !important;
    }

    .in-homepage-solution-gradient2 {
        top: 70vh;
        top: calc(var(--vh, 1vh) * 70);
    }

    .in-homepage-who-we-are-content-media > div {
        width: 100% !important;
        margin-right: 0 !important;
        margin-bottom: 8px !important;
    }

    .in-homepage-who-we-are-content-media-item > .flexColumn {
        margin-top: unset;
    }

    .in-homepage-who-we-are-content-media > .in-homepage-who-we-are-content-media-item:nth-child(1) {
        flex-direction: row;
    }

    .in-homepage-who-we-are-content-media > .in-homepage-who-we-are-content-media-item:nth-child(1) > div:first-child {
        flex-basis: 50%;
        margin-right: auto;
    }

    .in-homepage-who-we-serve-content, .in-homepage-roi-calc, 
    .in-homepage-benefits > .in-homepage-benefits-content,
    .in-homepage-faqs-content, .in-homepage-contact-us-content {
        padding: 60px 4vw;
    }

    .in-homepage-who-we-serve-media {
        justify-content: center;
    }

    .in-homepage-who-we-serve-media-item {
        max-width: 320px;
        flex-basis: 100%;
        margin-bottom: 16px;
        margin-right: 0 !important;
    }

    .in-homepage-roi-calc {
        width: 92vw;
        position: relative;
    }

    .in-homepage-roi-calc-content, .in-homepage-roi-calc-media {
        width: calc(100% - 48px);
        max-width: unset;
    }

    .in-homepage-faqs-content {
        flex-direction: column;
    }

    .in-homepage-faqs-text {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .in-homepage-contact-us-content {
        flex-direction: column;
    }

    .in-homepage-contact-us-media {
        display: none;
    }

    .in-homepage-contact-us-text {
        width: 92vw;
    }

    .in-homepage-pre-footer-content {
        flex-direction: column;
    }

    .in-homepage-pre-footer-radial2 {
        left: -25vw;
        top: calc(var(--vh, 1vh) * -106);
    }

    .in-homepage-pre-footer-content > .in-homepage-pre-footer-text {
        margin-bottom: 40px;
    }

    .in-homepage-footer {
        padding: 75px 4vw 40px;
    }

    .in-homepage-footer-content-top {
        padding-right: 0;
    }

    .in-homepage-footer-content-bottom > .flexRow {
        flex-direction: column;
    }

    .in-homepage-footer-content-bottom > .flexRow > div {
        border-right:  none !important;
    }
}




/* Add to Btn.css */
.in-homepage-container button {
    padding: 12px 15px;
}
.btn-primary-black {
    background-color: #0C1222;
    color: #fff;
}

.btn-radius6 {
    border-radius: 6px;
}

.btn-no-border {
    border: none !important;
}

.btn-no-bg {
    background: #ffffff00 !important;
}

.btn-no-break {
    white-space: nowrap;
}

.btn-secondary-selected {
    border-color: #006AFF;
    color: #006AFF;
}

.btn-primary {
    background-color: #006AFF !important;
}
