@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

/* For WebKit browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border: 1px solid #ccc; /* Border color */
}

::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded corners */
    border: 2px solid #ccc; /* Border around the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker thumb when hovered */
}

/* For Firefox */
* {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #888 #f1f1f1; /* Thumb and track color */
}

:root {
	--colorB1: #1890FF;
	--colorB2: #40A9FF;
	--colorB3: #096DD9;
	--colorB4: #001C41;
	--colorB5: #35A8CE;
	--colorB6: #053146;
	--colorB7: rgba(24, 144, 255, 0.2);
	--colorR1: #FF4D4F;
	--colorR2: #F5222D;
	--colorR3: #D25032;
	--colorR4: #FFF1F0;
	--colorR5: #FFA39E;
	--colorR4: rgba(245, 36, 46, 0.2);
	--colorG1: #5BA02E;
	--colorG2: rgba(4, 120, 23, 0.85);
	--colorG3: #52C41A;
	--colorG4: #B7EB8F;
	--colorG5: #F6FFED;
	--colorY1: #FAAD14;
	--colorY2: #FFE58F;
	--colorY3: #FFFBE6;
	--colorLight1: #FFFFFF;
	--colorLight2: #F9F9F9;
	--colorLight3: #F5F5F5;
	--colorLight4: #EBEBEB;
	--colorLight5: #D9D9D9;
	--colorLight6: #DDDFE2;
	--colorLight7: #A9A9A9;
	--colorLight8: #D7D7D7;
	--colorLight9: #8E8E8E;
	--colorLight10: #FAFAFA;
	--colorLight11: #C4C4C4;
	--colorDark1: #000000;
	--colorDark2: #262626;
	--colorDark3: rgba(0, 0, 0, 0.85);
	--colorDark4: rgba(0, 0, 0, 0.25);
	--colorDark5: rgba(0, 0, 0, 0.15);
	--colorDark6: rgba(0, 0, 0, 0.5);
	--colorDark7: rgba(196, 196, 196, 0.1);
}

.App {
	color: var(--colorDark3);
	margin: 0 auto;
	font-family: 'Manrope', 'Roboto';
}

.h1 {
	font-size: 24px;
	font-weight: bold;
}

.h2 {
	font-size: 18px;
	font-weight: bold;
}

.h3 {
	font-size: 16px;
	font-weight: bold;
	line-height: 25px;
}

.text-0 {
	font-size: 16px;
	font-weight: 400;
	line-height: 25px;
}

.text-1 {
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
}

.text-1-2 {
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
}

.text-1-3 {
	font-size: 14px;
	font-weight: bold;
	line-height: 22px;
}

.text-2 {
	font-size: 13px;
	font-weight: 400;
}

.text-3 {
	font-size: 12px;
	font-weight: 400;
	line-height: 22px;
}

.text-3-2 {
	font-size: 12px;
	font-weight: 500;
	line-height: 22px;
}

.text-4 {
	font-size: 11px;
	font-weight: 400;
}

.text-5 {
	font-size: 10px;
	font-weight: 400;
}

.para-label {
	padding: 1px 8px;
	border-radius: 2px;
	font-size: 12px;
	line-height: 22px;
	font-weight: 400;
	user-select: none;
}

.para-label.para-l-pass {
	background: var(--colorG5);
    color: var(--colorG3);
    border: solid 1px var(--colorG4);
}

.para-label.para-l-fail {
	background: var(--colorR4);
    color: var(--colorR2);
    border: solid 1px var(--colorR5);
}

.para-label.para-l-warn {
	background: var(--colorY3);
    color: var(--colorY1);
    border: solid 1px var(--colorY2);
}

.para-label.para-l-info {
	background: var(--colorB7);
    color: var(--colorB1);
    border: solid 1px var(--colorB3);
}

span.para-label.para-label-breakable {
	display: block;
	line-height: 16px;
}

.capitalizeText {
	text-transform: capitalize;
}

.text-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.ta-bottom {
	align-self: center;
}

.flexRow {
	display: flex;
	flex-direction: row;
}

.flexColumn {
	display: flex;
	flex-direction: column;
}

.align-middle {
	vertical-align: middle;
}

.shadow-box-1 {
    border: 1px solid rgba(221,223,226,.3215686274509804);
    padding: 8px 8px 16px;
    max-width: 800px;
    box-shadow: 0 1px 10px 2px rgb(0 0 0/8%);
    margin: auto;
}

.clickable {
	cursor: pointer;
}

.abs-right-0 {
	right: 0;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-8 {
	margin-bottom: 8px;
}

.ml-auto {
	margin-left: auto;
}

.mr-16 {
	margin-right: 16px;
}

.mr-8 {
    margin-right: 8px;
}

.space-right1 {
	margin-right: 8px;
}

.pad-20-all {
    padding: 20px;
}

.pad-5-ud {
	padding-top: 5px;
	padding-bottom: 5px;
}

.blue-headline {
    color: var(--colorB1);
    margin-bottom: 4px;
    /* font-size: 18px;
    font-weight: bold; */
}

.active-dd {
  	display: block !important;
}

.unselectable {
    user-select: none !important;
    -moz-user-select: none !important;
    -khtml-user-select: none !important;
    -webkit-user-select: none !important;
    -o-user-select: none !important;
}

.disable-cursor {
	cursor: unset !important;
}

.d-none {
	display: none !important;
}

.scroll-y-disable {
	overflow-y: hidden;
}

a {
	text-decoration: none;
}

.pos-relative {
	position: relative;
}

.disable-click {
	color: var(--colorDark4) !important;
	cursor: default !important;
	-webkit-user-select: none !important; /* Safari */        
	-moz-user-select: none !important; /* Firefox */
	-ms-user-select: none !important; /* IE10+/Edge */
	user-select: none !important; /* Standard */
	/* pointer-events: none; */
}

.d-flex-col {
	display: flex;
	flex-direction: column;
}

.absolute-center {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

::-webkit-input-placeholder, :-moz-placeholder, ::-moz-placeholder, :-ms-input-placeholder {
	font-family: 'Roboto';
	color:var(--colorDark4);
}

.input-container {
	margin-top: 16px;
}

.input-container > input, .input-container > textarea, .input-container-icon > .input-icon > input {
	padding: 8px;
	border-radius: 2px;
	border: solid 1px #dddfe2;
	resize: none;
	font-family: 'Roboto';
}

.input-container-icon > .input-icon > input {
	width: -moz-available;          /* WebKit-based browsers will ignore this. */
	width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
	width: fill-available;
}

.input-container > input.input-error, .input-container > textarea.input-error, .input-container-icon > .input-icon > input.input-error {
	border: 1px solid  #f5222d !important;
	margin-bottom: 8px;
}

.input-container > input:focus, .input-container > textarea:focus, .c-name-container:focus, .input-container-icon > .input-icon > input:focus {
	outline: none;
}

.input-container > div:first-of-type, .input-container-icon > div:first-of-type {
	font-weight: bold;
	margin-bottom: 10px;
}

.input-container-icon > .input-icon {
	position: relative;
}

.input-container-icon > .input-icon > img {
	position: absolute;
	right: 8px;
	top: 12px;
	width: 20px;
}

input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
}

input[type="number"]:focus {
    pointer-events: none;
}

.dd-container {
	cursor: pointer;
	width: fit-content;
	box-shadow: 0 0 10px 0 rgb(41 102 199 / 47%);
}

.dd-selector {
	border: 1px solid #dddfe2;
	border-radius: 2px;
	padding: 5px 12px;
}

.dd-selector > img {
  	margin: 0 5px;
}

.dd-more:hover {
  	cursor: pointer;
}

.dd-items {
	border: 1px solid #dddfe2;
}

.dd-item {
	border-bottom: 1px solid #dddfe2;
	padding: 5px 12px;
}

.checkbox-group-container {
	margin: 16px 0;
}

.checkbox-container-1 {
	cursor: pointer;
	padding: 6px 0;
	margin: 4px 0;
}

.checkbox-container-1:hover {
	background: var(--colorLight3);
}

.checkbox-container-1 > input.mul-selection-checkbox {
    height: 18px;
    width: 18px;
    margin: auto 10px auto 0;
    cursor: pointer;
}

.checkbox-container-1 > input:checked {
	accent-color: var(--colorB3);
}

.checkbox-group-container > .text-1-3 {
    margin-bottom: 8px;
}

.range-input {
	margin: 6px;
}

.range-input > input {
	border: 1px solid var(--colorLight5);
    border-radius: 2px;
    padding: 5px 12px;
    line-height: 24px;
    outline: none;
    width: calc(50% - 12px);
}

.range-input > span {
	font-size: 20px;
	font-weight: bold;
	margin: auto 16px;
}


.custom-scrollbar {
	border: none;
	width: calc(100% - 10px);
	overflow-y: auto;
	margin-right: 5px;
	padding-right: 5px;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 3px;
	background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: #2d527c;
	-webkit-border-radius: 20%;
	-moz-border-radius: 20%;
	border-radius: 20%;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  	background: #4c85db;
}

.custom-scrollbar-1 {
	scrollbar-color: #4293d5 #efefef;
	scrollbar-width: thin;
}

.custom-scrollbar-1::-webkit-scrollbar {
	width: 10px;
	background: #efefef;
}

.custom-scrollbar-1::-webkit-scrollbar-thumb {
  	background: #4293d5;
}

.custom-scrollbar-1::-webkit-scrollbar-thumb:hover {
  	background: #3d84bf;
}

.horizontal-scrollbar-1 {
	overflow-x: auto;
}

.horizontal-scrollbar-1::-webkit-scrollbar {
	height: 5px;
	background: #efefef;
}

.horizontal-scrollbar-1::-webkit-scrollbar-thumb {
	background: var(--colorB6);
}

.horizontal-scrollbar-1::-webkit-scrollbar-thumb:hover {
	background: var(--colorB4);
}

.width-in1000 {
	width: 1000px !important;
}

.max-w-in100 {
	max-width: 100px;
}

.max-w-in120 {
	max-width: 120px;
}

.max-w-in150 {
	max-width: 150px;
}

.max-w-in160 {
	max-width: 160px;
}

.max-w-in170 {
	max-width: 170px;
}

.max-w-in180 {
	max-width: 180px;
}

.max-w-in190 {
	max-width: 190px;
}

.max-w-in200 {
	max-width: 200px;
}

.max-w-in210 {
	max-width: 210px;
}

.max-w-in250 {
	max-width: 250px;
}

.max-w-in300 {
	max-width: 300px;
}

.min-w-in0 {
	min-width: 0;
}

@media only screen and (max-width: 1400px) {
	.in-lg\:max-w-in150 {
		max-width: 150px;
	}
}

@media only screen and (min-width: 1100px) {
	.tablet-only {
		display: none !important;
	}
	
	.lg\:min-w-80 {
		min-width: 20rem;
	}
}

@media only screen and (min-width: 750.01px) and (max-width: 1100px) {
	.hide-tablet {
		display: none !important;
	}

	.md\:min-w-80 {
		min-width: 20rem;
	}
}

@media only screen and (min-width: 750.01px) {
	.mobile-only {
		display: none !important;
	}
}

@media only screen and (max-width: 750px) {
	.hide-mobile {
		display: none !important;
	}

	.desktop-only {
		display: none !important;
	}

	.tablet-only {
		display: none !important;
	}

	.horizontal-scrollbar-1::-webkit-scrollbar {
		height: 0;
		background: transparent;
	}

	.sm\:max-w-in180 {
		max-width: 180px;
	}

	.sm\:min-w-36 {
		min-width: 9rem;
	}
}
